import React from 'react';
import styled from '@emotion/styled';
import {
	AccountIcon,
	ITheme,
	Responsive,
	StarsIcon,
	themed,
	Tooltip,
	UnstyledButton,
} from '@m2/blueprint-ui';

import { observer } from 'mobx-react';
import { MENU_TRANSITION_DELAY } from '../../../components/common/header/components/dropdown-menu/desktop/constants';
import { StyledSubMenu } from '../../../components/common/header/components/dropdown-menu/desktop/DropDownMenu';
import { useThrottledState } from '../../../utils/useThrottledState';
import { AccountMenuItemLoggedIn } from './AccountMenuItemLoggedIn';
import { AccountMenuItemLoggedOut } from './AccountMenuItemLoggedOut';
import { useUser } from '../../../auth/user/ui/useUser';
import { useAccountTooltip } from './helpers/useAccountTooltip';

interface IAccountMenuItemProps {
	isTransparent: boolean;
}

export const AccountMenuItem = observer(
	({ isTransparent }: IAccountMenuItemProps) => {
		const { isAccountCookieSet, setAccountCookie } = useAccountTooltip();
		const user = useUser();

		const [isSubMenuVisible, setIsSubMenuVisible] = useThrottledState<boolean>(
			MENU_TRANSITION_DELAY,
			false,
		);

		const handleAccountMenu = () => {
			if (!isAccountCookieSet) {
				setAccountCookie();
			}

			setIsSubMenuVisible(true);
		};

		const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
			const submenu = event.currentTarget;
			const relatedTarget = event.relatedTarget as HTMLElement;

			// if the current focused element is outside the menu, we want to collapse the submenu
			if (!submenu.contains(relatedTarget)) {
				setIsSubMenuVisible(false);
			}
		};

		const menuItemTitle = !user ? `Account` : `Hi, ${user.firstName}`;

		return (
			<AccountMenuWrapper
				onMouseEnter={handleAccountMenu}
				onMouseLeave={() => setIsSubMenuVisible(false)}
				onBlur={handleBlur}
			>
				<StyledAccountMenuItem
					data-testid="account-menu-item"
					isTransparent={isTransparent}
					onClick={handleAccountMenu}
				>
					<span className="menu-item">{menuItemTitle}</span>
					<AccountIcon aria-hidden />
				</StyledAccountMenuItem>

				<StyledAccountMenuSubMenu
					isVisible={isSubMenuVisible}
					data-testid="account-submenu-item"
					className="submenu"
				>
					{!user && <AccountMenuItemLoggedOut />}
					{user && <AccountMenuItemLoggedIn />}
				</StyledAccountMenuSubMenu>

				{!isAccountCookieSet && (
					<StyledAccountMenuTooltip position="bottomRight">
						<StyledAccountMenuTooltipContent>
							<StarsIcon />
							New: MyDFW account
						</StyledAccountMenuTooltipContent>
					</StyledAccountMenuTooltip>
				)}
			</AccountMenuWrapper>
		);
	},
);

const AccountMenuWrapper = styled.div`
	position: relative;
`;

const StyledAccountMenuItem = styled(UnstyledButton)<
	{ isTransparent: boolean },
	ITheme
>`
	position: relative;
	color: ${themed(({ color }, { isTransparent }) =>
		isTransparent ? color.text.inverted : color.text.default,
	)};
	display: flex;
	gap: 10px;
	align-items: center;
	cursor: pointer;
	--dropdown-submenu-height: auto;

	> svg path {
		fill: ${themed(({ color }, { isTransparent }) =>
			isTransparent ? color.text.inverted : color.highlight,
		)};
	}

	.menu-item {
		max-width: 100px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		align-items: flex-start;
		margin-bottom: 6px;
		.menu-item {
			display: none;
		}
	}
`;

const StyledAccountMenuTooltipContent = styled.div`
	display: flex;
	gap: 10px;
	color: white;
`;

const StyledAccountMenuTooltip = styled(Tooltip)`
	pointer-events: none;
`;

const StyledAccountMenuSubMenu = styled(StyledSubMenu)<
	{ isVisible: boolean },
	ITheme
>`
	left: unset;
	right: 0;
	width: 220px;
	height: auto;
	color: #000;
`;
