import React from 'react';
import {
	LogOutIcon,
	PolyComponent,
	UnstyledButton,
	UnstyledLi,
} from '@m2/blueprint-ui';
import Link from 'next/link';

import { useNavigate } from '../../../navigation';
import { StyledAccountMenuSubMenuItem } from './AccountMenuItemLoggedOut';
import { useLogout } from '../../../auth/common/hooks/useLogout';
import { CustomPageId } from '../../../contentful/routes';
import { useTranslation } from '../../../i18n/translate/useTranslation';

export const AccountMenuItemLoggedIn = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { handleLogOut } = useLogout();

	const onLogOut = () => {
		navigate('/');
		handleLogOut();
	};

	return (
		<>
			<UnstyledLi>
				<Link
					href={`/${CustomPageId.Account}/${CustomPageId.ParkingReservations}`}
					passHref
				>
					<StyledAccountMenuSubMenuItem className="no-border">
						{t('menu_item_account_reserve_parking')}
					</StyledAccountMenuSubMenuItem>
				</Link>
			</UnstyledLi>
			<UnstyledLi>
				<Link href={`/${CustomPageId.Account}`} passHref>
					<StyledAccountMenuSubMenuItem>
						{t('menu_item_account_mydfw_account')}
					</StyledAccountMenuSubMenuItem>
				</Link>
			</UnstyledLi>
			<UnstyledLi>
				<StyledPolyAccountMenuSubMenuItem
					as={UnstyledButton}
					aria-label={t('menu_item_account_sign_out')}
					onClick={onLogOut}
				>
					{t('menu_item_account_sign_out')} <LogOutIcon variation="brand" />
				</StyledPolyAccountMenuSubMenuItem>
			</UnstyledLi>
		</>
	);
};

const StyledPolyAccountMenuSubMenuItem: PolyComponent<'button'> =
	StyledAccountMenuSubMenuItem;
